import React, { Component } from 'react'

import Alert from '../components/Alert'
import Divider from '../icons/Divider'

class MainView extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      images: []
    }

    // const instagram = {
    //   accessToken: "1781384960.8b39678.d7e0592beae54fdbaceafd6c086aa86b",
    //   count: 10,
    //   recentImagesEndPoint: "https://api.instagram.com/v1/users/self/media/recent/"
    // }
    // window.$.get(`${instagram.recentImagesEndPoint}?access_token=${instagram.accessToken}&count=${instagram.count}`)
    //   .done((data) => this.setState( { images: data.data } ))
    //   .fail(() => console.warn('Instagram not responding'))
  }

  render() {
    const instafeedTarget = "minkakainsta"
    var instafeedElement = document.getElementById(instafeedTarget)
    if (instafeedElement) instafeedElement.innerHTML = ''

    const imageSet = this.props.shared.imageSets[this.props.view.data.imageSet]

    return (
      <div className="container main">
        {this.props.view.data.alert && this.props.view.data.alert.map((a, i) => 
            <Alert key={i} data={a} changeView={this.props.changeView} />
        )}
        <div className="clearfix">
          <div className="main-image">
            <img src={imageSet.mainImage} alt="" />
          </div>
          <div className="secondary-image">
            {imageSet.secondaryImage.map((image) => 
              <img key={image} src={image} alt="" />
            )}
          </div>
        </div>
        <h1>{this.props.view.data.title}</h1>
        {this.props.view.data.body.map((paragraph, index) =>
          <p key={index}>{paragraph}</p>
        )}
        <p>
          {this.props.view.data.openMenuLink[0]}
          <a role="button" href="#" className="mobile" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive">
            {this.props.view.data.openMenuLink[1]}
          </a>
          <span className="desktop">{this.props.view.data.openMenuLink[1]}</span>
          {this.props.view.data.openMenuLink[2]}
        </p>
        <Divider />
      </div>
    )
  }
}

export default MainView