import React, { Component } from 'react'

import OrderManager from '../OrderManager'
import ProductManager from '../ProductManager'

import Divider from '../icons/Divider'
import LocationSelect from '../components/LocationSelect'
import Modal from '../components/Modal'
import ProductAndSizeSelect from '../components/ProductAndSizeSelect'
import WaitModal from '../components/WaitModal'
import SelectedProducts from '../components/SelectedProducts'

class OrderView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dialogData: {},
      order: OrderManager.order
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.removeProduct = this.removeProduct.bind(this)
    this.startSending = this.startSending.bind(this)
    this.sendingFailed = this.sendingFailed.bind(this)
    this.sendingSucceeded = this.sendingSucceeded.bind(this)
  }

  handleInputChange(e) {
    const prop = e.target.name.split('.')
    if (prop.length > 1) OrderManager.order[prop[0]][prop[1]] = e.target.value
    else OrderManager.order[prop[0]] = e.target.value
    OrderManager.toCache()
    this.setState({ order: OrderManager.order })
  }

  next(e, name) {
    if (e.key === "Enter") {
      document.getElementsByName(name)[0].focus()
      e.preventDefault()
    }
  }

  removeProduct(selected) {
    OrderManager.removeProduct(selected)
    this.props.onSelectedProductsUpdate()
  }

  startSending(event) {
    event.preventDefault()
    if (! OrderManager.order.pickupLocation || OrderManager.order.pickupLocation.name.length <= 0) {
      document.querySelector('.location-select>div.form-control').focus()
      window.setTimeout(() => 
        window.$('.location-select')
          .popover({
            'content': this.props.view.data.form.pickupLocationLabel,
            'trigger': 'manual',
            'placement': 'top',
            'container': 'div.container.order' 
          })
          .popover('show'), 100)
      window.setTimeout(() => window.$('.location-select').popover('hide'), 2000)
      return
    }
    if (OrderManager.order.products.length <= 0) {
      let selected = this._productSelector.selection
      if (selected.product) {
        if (OrderManager.addProduct(selected.product, selected.size).length <= 0) {
          return
        }
      }
      else {
        return
      }
    }
    window.$('#waitModal').modal('show')
  }

  sendingFailed(response, status, ex) {
    var dialogData = this.props.view.data.form.failedDialog
    dialogData.centerBody = true
    if (response.readyState === 0) {
      dialogData.body[1] = dialogData.noNetwork
    }
    else {
      dialogData.body[1] = `${dialogData.serverError} (${ex})`
      dialogData.dynamicBody = OrderManager.orderAsHtmlRows(this.props.view.data.form)
    }
    
    this.setState({ dialogData: dialogData })

    window.$('#waitModal').modal('hide')
  }

  sendingSucceeded() {
    var dialogData = this.props.view.data.form.successDialog
    dialogData.dynamicBody = OrderManager.orderAsHtmlRows(this.props.view.data.form)
    OrderManager.clear()
    this._productSelector.reset()
    
    this.setState( { dialogData: dialogData, order: OrderManager.order })
    
    window.$('#waitModal').modal('hide')

    this.props.onSelectedProductsUpdate()
  }

  componentDidMount() {
    window.$('#waitModal')
      .on('shown.bs.modal', () => {
        OrderManager.send(this.props.view.data.emailTemplate, this.props.view.data.form)
          .done(this.sendingSucceeded)
          .fail(this.sendingFailed)
      })
      .on('hidden.bs.modal', () => {
        window.$('#sendingDoneModal').modal('show')
      })
  }

  render() {
    const form = this.props.view.data.form

    return (
      <div className="container order">
        <div className="row">
          <div className="col">
            {this.props.view.data.content.map((block, index) => 
              <div key={index}>
                <h2>{block.title}</h2>
                {block.body.map((paragraph, index) =>
                  <p key={index}>{paragraph}</p>
                )}
              </div>
            )}
          </div>
        </div>

        <Divider />

        <WaitModal id="waitModal" />
        <Modal id="sendingDoneModal" 
          data={this.state.dialogData} 
          closeLabel={this.props.content.texts.closeLabel} />
      </div>
    )
  }
}

export default OrderView