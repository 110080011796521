export default {
  fi: {
    views: [
      {
        id: 1, title: "Min Kaka",
        data: {
          title: "Sama kakku kaikille",
          imageSet: 0,
          body: [
            "Teemme gluteenittomia kakkuja, sekä makeita että suolaisia leivonnaisia, jotka maistuvat kaikille - parhaista raaka-aineista.",
          ],
          openMenuLink: [
            "Tutustu ",
            "tuotteisiimme",
            " ja ota yhteyttä!",
          ],
          alert: [
          //   {
          //     icon: "christmasTree",
          //     title: "Kesälomalla maanantaisin ja tiistaisin",
          //     body: [
          //       "Kesä-, heinä- ja elokuussa leipomomme on maanantaisin ja tiistaisin kiinni.",
          //       "Ihanaa kesää!"
          //     ],
          //   },
            {
              icon: "suitcase",
              title: "Min Kaka on lopettanut toimintansa 31.12.2024",
              body: [
                "Kiitämme asiakkaitamme ja yhteistyökumppaneitamme kuluneista yhteisistä vuosista."
              ],
            },
          ],
          instagramLabel: ""
        }
      },
      {
        id: 2, title: "Kakut", type: "products",
        // data: {
        //   body: [
        //     "Valikoimamme on rajattu kesäkaudella, 3.5. - 29.8.2021. Syksyllä tarjoamme taas kaikkia kakkujamme!"
        //   ]
        // },
        products: [
          // {
          //   id: 19, name: "Vessapaperi&shy;rulla",
          //   image: 35,
          //   allergens: "Gluteeniton, kananmunaton, maidoton, pähkinätön, soveltuu vegaaneille",
          //   description: "Valkoiseen sokerimassaan kääritty suklaavadelmaherkku, jonka saa leivoksena tai kakkuna.",
          //   ingredients: [29, 43, 39, 22, 0, 5, 27, 79, 60, 25, 49, 6],
          //   prices: 0
          // },
          {
            id: 1, name: "Mansikka&shy;pilvi vaniljalla",
            image: 0,
            allergens: "Gluteeniton, laktoositon, pähkinätön",
            description: "Sokerikakkupohja, jonka välissä mansikkahilloa, ihanaa vaniljakreemiä ja kermaa. Kuorrutteena on suomalaisia pakastemansikoita ja pursotettu kermavaahtoreuna.",
            ingredients: [1, 5, 10, 22, 28, 46, 35, 78, 9, 0],
            prices: 0
          },
          {
            id: 2, name: "Vadelma&shy;tarha",
            image: 1,
            allergens: "Gluteeniton, laktoositon, pähkinätön",
            description: "Sokerikakkupohja, jonka välissä on vadelmahilloa, vadelmamurskaa ja vadelmainen rahkakerma. Kuorrutteena on suomalaisia pakastevadelmia ja pursotettu kermavaahtoreuna.",
            ingredients: [1, 5, 10, 22, 28, 31, 79, 45, 9, 0],
            prices: 0
          },
          // {
          //   id: 11, name: "Kinuski-puolukka&shy;kakku",
          //   image: 10,
          //   allergens: "Gluteeniton, laktoositon, pähkinätön",
          //   description: "Sokerikakkupohja, jonka välissä on puolukkahilloa, kinuskia ja puolukkaista rahkakermatäytettä. Kuorrutteena on kinuskia, sokerimassakukka ja pursotettu kermavaahtoreuna.",
          //   ingredients: [1, 5, 10, 22, 28, 31, 47, 36, 39, 41, 0],
          //   prices: 0
          // },
          {
            id: 7, name: "Prinsessa&shy;kakku",
            image: 6,
            allergens: "Gluteeniton, laktoositon",
            description: "Sokerikakkupohja, jonka välissä on vadelmamurskaa, vadelmahilloa, vaniljakreemiä ja kermaa. Kuorrutteena on ihanan maukasta marsipaania ja sen alla ohut kerros voikreemiä.",
            ingredients: [1, 5, 10, 22, 79, 45, 28, 35, 24, 42, 41, 0],
            prices: 0
          },
          {
            id: 6, name: "Hunaja&shy;kakku",
            image: 5,
            allergens: "Gluteeniton, laktoositon, pähkinätön",
            description: "Viisi kerroksinen pehmeä hunajakakku, jonka välissä on smetanakreemia, ja kuorrutteena sokerimassakukka, kreemiä ja hunajakakkumuruja. Ihana makuyhdistelmä!",
            ingredients: [30, 5, 1, 24, 4, 22, 39, 41],
            prices: 0
          },
          {
            id: 3, name: "Appelsiini&shy;suklaa&shy;kakku",
            image: 2,
            allergens: "Gluteeniton, pähkinätön",
            description: "Suklaakakkupohjia, jonka välissä on appelsiinimarmelaadia, tummasuklaamoussea ja valkosuklaamoussea. Kuorrutteena on valuvaa suklaata ja koriste on valkosuklaata.",
            ingredients: [28, 43, 44, 106, 1, 5, 59, 24, 22, 27, 61, 40, 0],
            prices: 0
          },
          {
            id: 4, name: "Tripla&shy;suklaa&shy;kakku",
            image: 3,
            allergens: "Gluteeniton, pähkinätön",
            description: "Suklaakakkupohja, jonka päällä on kerroksittain suussa sulavaa tumma- ja valkosuklaamoussea. Kuorrutteena on suklaatryffeliä.",
            ingredients: [28, 43, 44, 59, 1, 5, 22, 24, 27, 61, 100, 102, 101, 40, 0],
            prices: 0
          },
          {
            id: 5, name: "Allergikon suklaakakku",
            image: 4,
            allergens: "Gluteeniton, kananmunaton, maidoton, pähkinätön, soveltuu vegaaneille",
            description: "Suklaakakkupohja, jonka päällä on kerros täyteläistä suklaamoussea ja pinnalla suklaapeili.",
            ingredients: [29, 43, 22, 0, 5, 27, 60, 49, 61, 6, 63, 128],
            prices: 2
          },
          {
            id: 14, name: "Cashew&shy;uunikakku",
            image: 41,
            allergens: "Gluteeniton, maidoton, kananmunaton",
            description: "Täyteläinen ja raikas uunissa kypsennetty kakku, jossa maistuu sitruuna ja vadelma.",
            ingredients: [22, 73, 125, 5, 25, 29, 85, 79, 128, 63],
            prices: 0
          },
          {
            id: 10, name: "Musta&shy;herukka&shy;suklaa&shy;kakku",
            image: 9,
            allergens: "Gluteeniton, pähkinätön, maidoton",
            description: "Suklaakakkupohjan päällä on mustaherukka- ja suklaamoussea, kuorrutteena mustaherukkapeili.",
            ingredients: [1, 29, 66, 43, 22, 27, 61, 0, 59, 100, 101],
            prices: 0
          },
          // {
          //   id: 15, name: "Caffe Latte",
          //   image: 13,
          //   allergens: "Gluteeniton, pähkinätön",
          //   description: "Keksipohjan päällä on pehmeän mokkaamainen mousse. Kuorrutteena on marmoroitu valkosuklaakiille.",
          //   ingredients: [32, 23, 28, 44, 22, 24, 5, 59, 49, 43],
          //   prices: 2
          // },
          {
            id: 13, name: "Basilika-valko&shy;suklaa&shy;kakku",
            image: 12,
            allergens: "Gluteeniton, pähkinätön",
            description: "Sokerikakkupohjan päällä on pehmeästi basilikan ja valkosuklaan makuinen mousse, jonka kruunaa mansikkakiille.",
            ingredients: [28, 31, 44, 78, 5, 1, 22, 59, 0, 92, 62],
            prices: 0
          },
          // {
          //   id: 12, name: "Glaseerattu lime&shy;mansikka&shy;kakku",
          //   image: 11,
          //   allergens: "Gluteeniton, pähkinätön",
          //   description: "Keksipohjan päällä on raikas limemousse, jossa on mansikkaraidat. Kuorrutteena on kiiltävä marmoroitu valkosuklaapinta.",
          //   ingredients: [32, 28, 83, 5, 78, 22, 24, 44, 8, 59, 41],
          //   prices: 2
          // },
          {
            id: 9, name: "Mango-passion&shy;mousse&shy;kakku",
            image: 8,
            allergens: "Gluteeniton, laktoositon, pähkinätön",
            description: "Keskipohjan päällä mangomoussekerros, jonka päällä on kuorrutteena passionkiille.",
            ingredients: [103, 28, 32, 86, 104, 5, 24, 59, 22],
            prices: 0
          },
          {
            id: 8, name: "Uunijuusto&shy;kakku",
            image: 7,
            allergens: "Gluteeniton, laktoositon, pähkinätön",
            description: "Keksipohjan päällä on täyteläinen juustotäyte, missä maistuu lime. Pinnalla on pehmeää marenkia.",
            ingredients: [32, 30, 1, 5, 81, 22, 24, 84, 0],
            prices: 0
          },
        ],
      },
      {
        id: 3, title: "Suolaiset ja Leivokset", type: "products",
        products: [
          {
            id: 110, name: "Lohi&shy;voileipä&shy;kakku",
            image: 24,
            allergens: "Gluteeniton, laktoositon, pähkinätön",
            description: "Leipäpohjan päällä on herkullinen lämmisavulohimousse, koristeena sitruunaa, tilliä ja punasipulia.",
            ingredients: [32, 28, 52, 94, 24, 59, 85, 91, 5, 131, 121],
            prices: 4
          },
          {
            id: 111, name: "Kinkku&shy;voileipä&shy;kakku",
            image: 25,
            allergens: "Gluteeniton, laktoositon, pähkinätön",
            description: "Leipäpohjan päällä on maukas kinkkumousse, koristeena tuoreita vihanneksia.",
            ingredients: [32, 28, 51, 93, 94, 95, 96, 24, 59, 97, 98, 53],
            prices: 0
          },
          {
            id: 113, name: "Saaristolais&shy;voileipä&shy;kakku",
            image: 37,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Makean saaristoisleipäkerrosten välissä herkullinen savutofutillitäyte ja päällä mummonkurkkua ja hillottua punasipulia.",
            ingredients: [121, 125, 127, 94, 95, 126, 91, 130, 85, 0, 5, 131],
            prices: 4
          },
          {
            id: 109, name: "Välimerel&shy;linen piirakka - iso ja pieni",
            image: 23,
            allergens: "Gluteeniton, pähkinätön",
            description: "Juustoisen kuoren sisällä on kermainen välimerellinen täyte.",
            ingredients: [28, 33, 24, 31, 1, 34, 54, 97, 118, 22],
            prices: 11
          },
          {
            id: 120, name: "Tomaatti-tofu&shy;piirakka (1 hlö)",
            image: 45,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Maukkaan pohjan päällä on kevyesti savunmakuinen tofutäyte, jota piristää tomaatti ja sipuli.",
            ingredients: [142, 127, 94, 96, 15, 25, 125, 136, 137, 20, 27, 58, 64, 98],
            prices: 11
          },
          {
            id: 117, name: "Kinkku-purjo&shy;piirakka (1 hlö)",
            image: 42,
            allergens: "Gluteeniton, pähkinätön",
            description: "Perinteinen kermainen ja juustoinen kinkkupurjopiirakka.",
            ingredients: [28, 33, 24, 31, 1, 51, 140, 96, 22],
            prices: 11
          },
          {
            id: 118, name: "Riisi&shy;piirakka",
            image: 43,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Rapsakan kuoren sisällä kermainen kaurajuomaan tehty riisipuurotäyte.",
            ingredients: [117, 135, 15, 0, 27, 136, 137, 20, 38, 58, 129],
            prices: 3
          },
          {
            id: 119, name: "Kaneli&shy;pulla",
            image: 44,
            allergens: "Gluteeniton, laktoositon",
            description: "Aidon pullan pehmeys ja suutuntuma.",
            ingredients: [120, 138, 1, 5, 24, 3, 67, 139],
            prices: 12
          },
          // {
          //   id: 101, name: "Porkkana&shy;leivos",
          //   image: 15,
          //   allergens: "Gluteeniton, laktoositon",
          //   description: "Mehevän porkkanakakun päällä on raikas kreemi, jossa on hitusen sitruksen makua.",
          //   ingredients: [99, 1, 5, 27, 12, 75, 21, 28, 82, 11, 10, 65, 41, 76],
          //   prices: 14
          // },
          {
            id: 102, name: "Passion&shy;leivos",
            image: 16,
            allergens: "Gluteeniton, laktoositon, pähkinätön",
            description: "Keksipohjan päällä on täytteenä ihanan raikas passiontahna ja kuorrutteena pehmeä marenki.",
            ingredients: [1, 24, 5, 86, 22, 0, 63],
            prices: 12
          },
          {
            id: 104, name: "Kinuski-pähkinä&shy;leivos",
            image: 18,
            allergens: "Gluteeniton, laktoositon",
            description: "Keksipohjan päällä on täytteenä pehmeää kinuskia ja paahdettuja pähkinöitä.",
            ingredients: [36, 73, 74, 24, 1, 5, 22],
            prices: 12
          },
          {
            id: 107, name: "Banaani&shy;cupcake",
            image: 21,
            allergens: "Gluteeniton, vegaaninen, maidoton, kananmunaton",
            description: "Suussa sulava banaanicupcake, jonka kuorrutteena on maapähkinäkreemiä.",
            ingredients: [5, 88, 25, 22, 114, 115, 116, 117, 60, 11, 74],
            prices: 12
          },
          {
            id: 112, name: "Porkkana&shy;cupcake",
            image: 36,
            allergens: "Gluteeniton, vegaaninen, maidoton, kananmunaton",
            description: "Suussa sulava porkkanacupcake, jonka kuorrutteena on vaniljakreemiä.",
            ingredients: [25, 5, 22, 99, 123, 29, 60, 65, 11, 62],
            prices: 12
          },
          {
            id: 114, name: "Banaani&shy;leipä (450g)",
            image: 40,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Herkullinen, mehevä ja makea kanelinen kahvileipä, jossa on ihana suutuntuma.",
            ingredients: [88, 117, 5, 26, 17, 16, 132, 60, 65, 11],
            prices: 13
          },
          {
            id: 108, name: "Saaristolais&shy;leipä (500g)",
            image: 22,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Makea ja rouheinen leipä",
            ingredients: [77, 7, 13, 14, 15, 16, 17, 18, 0, 3, 38, 19, 58, 20],
            prices: 13
          },
          {
            id: 115, name: "Vaalea vuokaleipä (1000g)",
            image: 39,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Hyvin viipaloituva, voileiväksi tai ruokaleiväksi soveltuva mehevä leipä.",
            ingredients: [0, 22, 15, 132, 27, 99, 26, 3, 20, 58],
            prices: 6
          },
          {
            id: 116, name: "Siemensämpylä (100g)",
            image: 38,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Rapsakka pinta ja pehmeä sisältä.",
            ingredients: [0, 22, 15, 132, 27, 133, 26, 3, 20, 58],
            prices: 5
          },
          // {
          //   id: 121, name: "Bagel",
          //   image: 46,
          //   allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
          //   description: "???.",
          //   ingredients: [0, 21, 141, 27, 3, 58, 11, 5],
          //   prices: 5
          // },

        ],
      },
      {
        id: 4, title: "Sesonkituotteet", type: "products",
        products: [

          {
            id: 203, name: "Pipar&shy;kakku&shy;rasia (140&nbsp;g)",
            image: 27,
            allergens: "Gluteeniton, pähkinätön",
            description: "Rasia sisältää 12-14 piparkakkua. Saatavilla viikoilla 48 - 51.",
            ingredients: [22, 5, 24, 7, 1, 65, 67, 11, 68, 69],
            prices: 25
          },
          {
            id: 205, name: "Joulu&shy;tortut",
            image: 29,
            allergens: "Gluteeniton, laktoositon, pähkinätön",
            description: "Saatavilla viikoilla 48 - 51. Puolikuun muotoiset joulutortut, joissa on reilusti itsetehtyä luumutäytettä.",
            ingredients: [22, 31, 24, 90, 5, 0, 10, 27],
            prices: 26
          },
          {
            id: 204, name: "Taatelikakku à 500g",
            image: 28,
            allergens: "Gluteeniton, laktoositon, pähkinätön",
            description: "Saatavilla viikoilla 48 - 51. Suussa sulava perinteinen taatelikakku, Joulun herkku!",
            ingredients: [5, 22, 89, 25, 0, 1, 28, 6, 11, 10],
            prices: 27
          },
          {
            id: 202, name: "Glögikakku",
            image: 26,
            allergens: "Gluteeniton, laktoositon, pähkinätön",
            description: "Saatavilla viikoilla 48 - 51. Piparkakkupohjan päällä on kanelinen omenamoussetäyte ja kuorrutteena glögikiille. Koristeena on kanelitankoja ja tähtianiksia.",
            ingredients: [32, 28, 56, 87, 5, 24, 57, 59, 65, 70],
            prices: 0
          },
          // {
          //   id: 103, name: "Puolukka-vaniljaleivos",
          //   image: 17,
          //   allergens: "Gluteeniton, laktoositon, pähkinätön",
          //   description: "Saatavilla viikoilla 48 - 51. Keksipohjan päällä on täytteenä puolukkahilloa ja vaniljakreemiä, ja kuorrutteena pehmeä marenki.",
          //   ingredients: [35, 47, 1, 5, 22, 80, 0],
          //   prices: 12
          // },

          {
            id: 201, name: "Runebergin&shy;tortut",
            image: 30,
            allergens: "Gluteeniton, laktoositon",
            description: "Saatavilla viikoilla 2 - 5. Rommivedellä kostutetut ja karvasmantelilla maustetut runebergintortut.",
            ingredients: [22, 105, 5, 76, 1, 0, 72, 48, 37, 124, 82],
            prices: 21
          },

          {
            id: 213, name: "Munkit (60g)",
            image: 34,
            allergens: "Gluteeniton, vegaaninen, maidoton, kananmunaton",
            description: "Tällä hetkellä saatavilla vain vapuksi, myytävinä tiistaina 30.4..",
            ingredients: [117, 5, 16, 112, 25, 19, 20, 122, 11, 67, 134, 6],
            prices: 24
          },

          {
            id: 210, name: "Mämmi à 450g",
            image: 31,
            allergens: "Gluteeniton, vegaaninen, maidoton, kananmunaton",
            description: "Saatavilla vk 11 - 14.",
            ingredients: [0, 7, 107, 90, 13, 38, 5, 108, 58],
            prices: 22
          },
          {
            id: 211, name: "Pasha à 200g",
            image: 32,
            allergens: "Gluteeniton, laktoositon",
            description: "Saatavilla vk 11 - 14.",
            ingredients: [31, 28, 5, 109, 24, 106, 76, 110],
            prices: 23
          },
          {
            id: 212, name: "Pääsiäiskakku",
            image: 33,
            allergens: "Gluteeniton, laktoositon",
            description: "Saatavilla vk 11 - 14. Keksipohjan päällä pashatäyte ja kullankeltainen mangopeili.",
            ingredients: [22, 24, 113, 103, 59, 43, 39],
            prices: 0
          },
        ],
      },
      {
        id: 7, title: "Vegaaniset", type: "products",
        products: [
          {
            id: 5, name: "Allergikon suklaakakku",
            image: 4,
            allergens: "Gluteeniton, kananmunaton, maidoton, pähkinätön",
            description: "Suklaakakkupohja, jonka päällä on kerros täyteläistä suklaamoussea ja pinnalla suklaapeili.",
            ingredients: [29, 43, 22, 0, 5, 27, 60, 49, 61, 6, 63, 128],
            prices: 2
          },
          {
            id: 14, name: "Cashew&shy;uunikakku",
            image: 41,
            allergens: "Gluteeniton, maidoton, kananmunaton",
            description: "Täyteläinen ja raikas uunissa kypsennetty kakku, jossa maistuu sitruuna ja vadelma.",
            ingredients: [22, 73, 125, 5, 25, 29, 85, 79, 128, 63],
            prices: 0
          },
          {
            id: 113, name: "Saaristolais&shy;voileipä&shy;kakku",
            image: 37,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Makean saaristoisleipäkerrosten välissä herkullinen savutofutillitäyte ja päällä mummonkurkkua ja hillottua punasipulia.",
            ingredients: [121, 125, 127, 94, 95, 126, 91, 130, 85, 0, 5, 131],
            prices: 4
          },
          {
            id: 107, name: "Banaani&shy;cupcake",
            image: 21,
            allergens: "Gluteeniton, maidoton, kananmunaton",
            description: "Suussa sulava banaanicupcake, jonka kuorrutteena on maapähkinäkreemiä.",
            ingredients: [5, 88, 25, 22, 114, 115, 116, 117, 60, 11, 74],
            prices: 12
          },
          {
            id: 112, name: "Porkkana&shy;cupcake",
            image: 36,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Suussa sulava porkkanacupcake, jonka kuorrutteena on vaniljakreemiä.",
            ingredients: [25, 5, 22, 99, 123, 29, 60, 65, 11, 62],
            prices: 12
          },
          {
            id: 120, name: "Tomaatti-tofu&shy;piirakka (1 hlö)",
            image: 45,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Maukkaan pohjan päällä on kevyesti savunmakuinen tofutäyte, jota piristää tomaatti ja sipuli.",
            ingredients: [142, 127, 94, 96, 15, 25, 125, 136, 137, 20, 27, 58, 64, 98],
            prices: 11
          },
          {
            id: 118, name: "Riisi&shy;piirakka",
            image: 43,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Rapsakan kuoren sisällä kermainen kaurajuomaan tehty riisipuurotäyte.",
            ingredients: [117, 135, 15, 0, 27, 136, 137, 20, 38, 58, 129],
            prices: 3
          },
          {
            id: 114, name: "Banaani&shy;leipä (450g)",
            image: 40,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Herkullinen ja mehevä makea kanelinen kahvileipä, jossa on ihana suutuntuma.",
            ingredients: [88, 117, 5, 26, 17, 16, 132, 60, 65, 11],
            prices: 13
          },
          {
            id: 213, name: "Munkit (60g)",
            image: 34,
            allergens: "Gluteeniton, maidoton, kananmunaton",
            description: "Tällä hetkellä saatavilla vain vapuksi, myytävinä tiistaina 30.4..",
            ingredients: [117, 5, 16, 112, 25, 19, 20, 122, 11, 67, 134, 6],
            prices: 24
          },
          {
            id: 108, name: "Saaristolais&shy;leipä (500g)",
            image: 22,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Makea ja rouheinen leipä",
            ingredients: [77, 7, 13, 14, 15, 16, 17, 18, 0, 3, 38, 19, 58, 20],
            prices: 13
          },
          {
            id: 115, name: "Vaalea vuokaleipä (1000g)",
            image: 39,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Hyvin viipaloituva, voileiväksi tai ruokaleiväksi soveltuva mehevä leipä.",
            ingredients: [0, 22, 15, 132, 27, 99, 26, 3, 20, 58],
            prices: 6
          },
          {
            id: 116, name: "Siemensämpylä (100g)",
            image: 38,
            allergens: "Gluteeniton, maidoton, kananmunaton, pähkinätön",
            description: "Rapsakka pinta ja pehmeä sisältä.",
            ingredients: [0, 22, 15, 132, 27, 133, 26, 3, 20, 58],
            prices: 5
          },
        ],
      },
      {
       id: 10, title: "Tausta", type: "background",
        data: {
          title: "Tausta",
          imageSet: 1,
          body: [
            "Olen kondiittori, joka toivoo että juhlissa kaikki voisivat syödä samaa kakkua, mausta tinkimättä. Siksi haluan tehdä gluteenittomia tuotteita jotka maistuvat ja näyttävät vähintään yhtä hyvälle kuin ”normaalit”.",
            "Haaveenani oli pitkään oma, täysin gluteeniton leipomo. Nyt minulla on se Helsingin Hermannissa ja siellä teemme suolaisia ja makeita leivonnaisia, jotka maistuvat ja sopivat tasa-arvoisesti kaikille.",
            "Sama kakku kaikille!"
          ]
        }
      },
      {
        id: 20, title: "Tilaa", type: "order",
        data: {
          emailTemplate: "fi",
          form: {
            title: "Tilaus",
            toggleAddProductFormLabel: "Lisää tilattava tuote",
            productLabel: "Tuote",
            sizeLabel: "Koko",
            addProductLabel: "Lisää",
            totalPriceLabel: "Yhteensä",
            pickupSectionLabel: "Haluamasi noutoaika",
            pickupLocationLabel: "Noutopaikka",
            pickupTimeLabel: "Noutoaika, esim. 1.2.2021 klo 14.00",
            notesLabel: "Muita toiveita tai pyyntöjä",
            contactSectionLabel: "Yhteystietosi",
            nameLabel: "Nimi",
            emailLabel: "Sähköpostiosoite",
            phoneLabel: "Puhelinnumero, esim. 050 123456789",
            pickupFeeLabel: "Toimitusmaksu",
            pickupLocations: [
              {
                id: 1,
                name: "Hermanni, Min Kaka",
                pickupTimes: "Voitte noutaa tilauksenne arkisin klo 10-15, sovittuna aikana. Maksuvaihtoehtoina käteinen ja korttimaksu.",
                mapLink: 0,
                cost: 0,
              },
              {
                id: 5,
                name: "Sörnäinen, Eromanga (la 9-14)",
                pickupTimes: "Voitte noutaa tilauksenne lauantaisin klo 9-14. Maksu vain laskulla.",
                mapLink: 7,
                cost: 5,
              },
              // {
              //   id: 3,
              //   name: "Senaatintori, Tuomaan markkinat VAIN 20.-22.12.",
              //   pickupTimes: "Olemme mökissä 26 perjantaina 11-20, ja lauantaina ja sunnuntaina 10-20.",
              //   mapLink: 4,
              //   cost: 0,
              // },
              // {
              //   id: 4,
              //   name: "Vallilan siirtolapuutarhan kioskikahvila 2.6. - 29.8.2020.",
              //   pickupTimes: "Voitte noutaa tilauksenne ke-pe 9-17 tai la-su 10-15. Maksuvaihtoehtoina käteinen, korttimaksu ja MobilePay.",
              //   mapLink: 5,
              //   cost: 0,
              // },
            ],
            orderLabel: "TILAA",
            successDialog: {
              title: "KIITOS!",
              body: [
                "Alustava tilauksesi on lähetetty meille - ja kopio siitä sinulle, antamaasi sähköpostiosoitteeseen.",
                "Vahvistamme tilauksen mahdollisimman pian, yleensä seuraavana arkipäivänä.",
              ],
            },
            failedDialog: {
              title: "TILAUKSEN LÄHETYS EPÄONNISTUI",
              body: [
                ":("
              ],
              noNetwork: "Verkkoyhteytesi ei toimi juuri nyt.",
              serverError: "Palvelussamme on häiriö, koetamme korjata sen mahdollisimman pian - odottaessa voit kopioida alta tilauksesi ja lähettää sen sähköpostitse meille. Pahoittelut!"
            }
          },
          content: [
            {
              title: "Min Kaka on lopettanut toimintansa 31.12.2024",
              body: [
                "Kiitämme asiakkaitamme ja yhteistyökumppaneitamme kuluneista yhteisistä vuosista."
              ],
            }
          ]
        }
      },
    ],

    ingredients: [
/* 0 */ "vesi",
/* 1 */ "kananmuna",
/* 2 */ "kananmunanvalkuainen",
/* 3 */ "hiiva",

/* 4 */ "hunaja",
/* 5 */ "sokeri",
/* 6 */ "vanilliinisokeri",
/* 7 */ "siirappi",
/* 8 */ "glukoosisiirappi",
/* 9 */ "hyytelösokeri (sokeri, karrageeni, johanneksenleipäpuujauhe)",

/* 10 */"leivinjauhe",
/* 11 */"sooda",
/* 12 */"mantelijauhe",
/* 13 */"tattarijauho",
/* 14 */"pellavansiemen",
/* 15 */"tumma riisijauho",
/* 16 */"riisijauho",
/* 17 */"kaurahiutale",
/* 18 */"tattarisuurimo",
/* 19 */"perunakuitu",
/* 20 */"psyllium",
/* 21 */"maissitärkkelys",
/* 22 */"Sempermix Hieno -jauheseos (riisijauho, maissitärkkelys, perunajauho, modifioitu perunatärkkelys, sokeri, sakeuttamisaineet (E412, E464, E401, E440), suola, nostatusaineet (E575, E500))",

/* 23 */"MAITO",
/* 24 */"laktoositon voi",
/* 25 */"margariini",
/* 26 */"oliiviöljy",
/* 27 */"rypsi- tai rapsiöljy",
/* 28 */"laktoositon kerma",
/* 29 */"SOIJAvalmiste (soijapohja (vesi, kuoritut SOIJAPAVUT (2,7%)), kasviöljyt (kokonaan kovetettu kookosöljy, kokonaan kovetettu rapsiöljy), maltodekstriini, auringonkukkaöljy, sokeri, emulgointiaineet (rasvahappojen sakkaroosiesterit, rasvahappojen mono- ja diglyseridien maitohappoesterit), sitruskuitu, merisuola, aromi)",
/* 30 */"smetana",
/* 31 */"rahka",

/* 32 */"laktoositon tuorejuusto",
/* 33 */"emmentaljuusto",
/* 34 */"salaattijuusto",

/* 35 */"vaniljakreemi (MAITO, kananmuna, sokeri, voi, maissitärkkelys)",
/* 36 */"kinuski (MAITO, sokeri, laktoositon kerma)",
/* 37 */"pomada",
/* 38 */"sokerikulööri (E150c)",
/* 39 */"sokerimassa",
/* 40 */"sokerihelmi",
/* 41 */"elintarvikeväri",
/* 42 */"marsipaani (sokeri, manteli, glukoosisiirappi, inverttisiirappi, vesi, säilöntäaine(E211, E1103))",
/* 43 */"tumma suklaa (kaakaomassa, sokeri, kaakaovoi, SOIJAlecitiini, luonnollinen vanilja-aroomi)",
/* 44 */"valkosuklaa (sokeri, kaakaovoi, kokomaitopulveri, SOIJAmaitolesitiini, luonnollinen vanilja-aromi)",
/* 45 */"vadelmahillo",
/* 46 */"mansikkahillo",
/* 47 */"puolukkahillo",
/* 48 */"vadelmamarmelaadi",
/* 49 */"Nescafe-pikakahvijauhe",
/* 50 */"salmiakkiuutetta",
/* 51 */"kinkku",
/* 52 */"lämminsavulohi",
/* 53 */"gluteeniton leipä (maissitärkkelys, vesi, hapantaikina 12% (riisijauho, vesi), riisitärkkelys, vilja 4,3% (hirssijauho 2,6%, quinoajauho 1,7%) kasvikuitua (psyllium), sokerijuurikassiirappi, riisisiirappi, auringonkukkaöljy, SOIJAhiutaleet 2,1%, auringonkukansiemenet 2,1%, SOIJAlese 1,9%, pellavansiemen 1,9%, sakeuttamisaine: hydroksipropyylimetyyliselluloosa; hirssihiutale 1,4%, SOIJAproteiini, hiiva, merisuolaa, hunajaa. Saattaa sisältää pieniä määriä lupiinia.)",
/* 54 */"aurinkokuivattu tomaatti",
/* 55 */"kuivatettu banaani",
/* 56 */"Min Kaka -piparkakku",
/* 57 */"glögi",

/* 58 */"suola",
/* 59 */"liivate",
/* 60 */"valkoviinietikka",
/* 61 */"kaakao",
/* 62 */"vanilja",
/* 63 */"syötävät kukat",
/* 64 */"mustapippuri",
/* 65 */"kaneli",
/* 66 */"mustaherukkapyre",
/* 67 */"kardemumma",
/* 68 */"neilikka",
/* 69 */"inkivääri",
/* 70 */"tähtianis",
/* 71 */"karvasmanteliöljy",
/* 72 */"rommiaromi",

/* 73 */"CASHEWPÄHKINÄ",
/* 74 */"HASSELPÄHKINÄ",
/* 75 */"HASSELPÄHKINÄROUHE",
/* 76 */"MANTELIROUHE",

/* 77 */"appelsiinimehu",
/* 78 */"mansikka",
/* 79 */"vadelma",
/* 80 */"puolukka",
/* 81 */"lime",
/* 82 */"limemehu",
/* 83 */"limepyre",
/* 84 */"viinimarja",
/* 85 */"sitruuna",
/* 86 */"passionpyre",
/* 87 */"omenasose",
/* 88 */"banaani",
/* 89 */"taateli",
/* 90 */"luumu",

/* 91 */"tilli",
/* 92 */"basilika",
/* 93 */"paprika",
/* 94 */"punasipuli",
/* 95 */"kurkku",
/* 96 */"tomaatti",
/* 97 */"rucola",
/* 98 */"valkosipuli",
/* 99 */"porkkana",

/* 100 */"marenki",
/* 101 */"tumma koristesuklaa",
/* 102 */"valkoinen koristesuklaa",
/* 103 */"mangopyre",
/* 104 */"passionhedelmä",
/* 105 */"voi",
/* 106 */"appelsiinimarmelaadi (appelsiini, sitruuna, sokeri, vesi)",
/* 107 */"peruna",
/* 108 */"pomeranssi",
/* 109 */"kananmunankeltuainen",

/* 110 */"rusina",
/* 111 */"jogurtti",
/* 112 */"perunajauho",
/* 113 */"Min Kaka -pasha",
/* 114 */"MAAPÄHKINÄ",
/* 115 */"auringonkukkaöljy",
/* 116 */"kovetettu palmurasva",
/* 117 */"kaurajuoma (kaurapohja (vesi, gluteeniton KAURAJAUHO, rypsi- ja rapsiöljy, kalsium, suola), vesi, happamuudensäätöaine (kaliumfosfaatit), jodi, vitamiinit (riboflaviini (B2), B12 ja D2))",
/* 118 */"oliivi",
/* 119 */"agavesiirappi",

/* 120 */"laktoositon MAITO",
/* 121 */"Min Kaka -saaristolaisleipä",
/* 122 */"sitruunapyre",
/* 123 */"ananas",
/* 124 */"karvasmanteliaromi",
/* 125 */"Sheese (vesi, kookosöljy, SOIJAproteiini tiiviste, suola, maitohappo (maidoton), sokeri, sakeuttamisaine (karrageeni), luontaiset aromit)",
/* 126 */"vegaaninen majoneesi (rapsiöljy, vesi, etikka, sokeri, suola, muunnettu maissitärkkelys, luontainen aromi (sisältää SINAPPIA), sitruunamehutiiviste, hapettumisenestoaine (E385), paprikauute)",
/* 127 */"savutofu (SOIJAPAVUT, vesi, merisuola, stabilointiaine (kalsiumsulfaatti), savuaromi)",
/* 128 */"agaragar",
/* 129 */"ksantaanikumi",

/* 130 */"kapris",
/* 131 */"väkiviinaetikka",
/* 132 */"auringonkukansiemen",
/* 133 */"kurpitsansiemen",
/* 134 */"rapsiöljy",
/* 135 */"riisi",
/* 136 */"muunneltu maissitärkkelys",
/* 137 */"perunahiutale (peruna, emulgointiaine (E471), stabilointiaine (E450), happeutumisenestoaine(E300), happamuudensäilöntäaineet(E330, E331))",
/* 138 */"jauhoseos (tärkkelys(maissi, peruna, tapioka), psyllium, inuliini, fruktoosi, sakeuttamisaineet(E412, E464), emulgointiaine(E401), kasvisproteini(herne), paahdettu maissijauhe, suola, säilöntäaine (E200), entsyymit)",
/* 139 */"kanelitäyte (sokeri, vesi, aprikoosinsiemen, glukoosisiirappi, modifioitu perunatärkkelys, margariini(palmuöljy, sheaöljy, rapsiöljy), suola, emulgointiaine (E771, E475), aromi, antidoxidanttiaine (E350), väriaine (E160a), MANTELI, kaneli, alkoholi, inverttisiirappi, säilöntäaine(E202), vaahterasiirappi)",

/* 140 */"purjosipuli",
/* 141 */"jauhosekoitus (venusriisi, durra, punainen teff, tattari, siemenjauhosekoitus (pellava, chia), kasvikuidut (psyllium, inuliini), fruktoosi, omenauute, sakeuttamisaineet (E412,E464), paahdettu maissijauho, entsyymit)",
/* 142 */"kasvisrasva-öljysekoitus (linssiproteiinivalmiste (vesi, 1,1% linssiproteiini), kookosrasva, rapsiöljy, sokeri, muunnettu msissitärkkelys, emulgointiaineet (auringonkukkalesitiini, rasvahappojen dokeriesterit, rasvahappojen mono- ja diglyseridit), stabilisaattori (guarkumi, johanneksenleipäpuukumi), luontaiset aromit, suola, väriaine (beetakaroteeni))",

    ],
    sizes: {
      "X": { single: "N. 4 hengelle" },
      "K": { single: "N. 8 hengelle" },
      "S": { single: "N. 10 hengelle" },
      "M": { single: "N. 15 hengelle" },
      "L": { single: "N. 20 hengelle" },
      "R": { single: "Alkaen 5€/pala - lopullinen hinta vahvistetaan erikseen" },
      "P": { single: "1 leivos", multiple: "%%COUNT%% kappaletta" },
      "p": { single: "1 pieni piirakka", multiple: "%%COUNT%% pientä piirakkaa" },
      "B": { single: "1 kappale", multiple: "%%COUNT%% kappaletta" },
      "A": { single: "1 rasia, yhdessä rasiassa on 12-14 piparkakkua", multiple: "%%COUNT%% rasiaa, yhdessä rasiassa on 12-14 piparkakkua" },
      "C": { single: "1 kappale", multiple: "%%COUNT%% kappaletta" },
    },
    texts: {
      ingredientLabel: "Tuoteseloste",
      addProductLabel: "Lisää tilaukseen",
      pricesLabel: "Valitse koko",
      closeLabel: "Ok",
    },
    contact: {
      name: "Bettina Winquist, Min Kaka",
      address: "Hermannin rantatie 20, 00580 Helsinki",
      phone: "(040) 562 4011",
      email: "bettina@minkaka.fi",
      oiva: {
        text: "Oiva-raportti",
        url: "https://www.oivahymy.fi/hae-yrityksia/#text=min%20kaka%20oy&",
      },
    }

  },
  sv: {
    views: [
      {
        id: 1, title: "Min Kaka",
        data: {
          title: "Samma kaka för alla",
          imageSet: 0,
            body: [
            "Vi gör glutenfria kakor, bakelser, bröd och annat salt, som smakar till alla - av de bästa råvarorna.",
          ],
          openMenuLink: [
            "Bekanta dig med ",
            "våra produkter",
            ", och ta kontakt!",
          ],
          alert: [
            {
              icon: "suitcase",
              title: "Min Kaka har avslutat sin verksamhet 31.12.2024",
              body: [
                "Vi tackar våra kunder och samarbetspartners för de gemensamma åren."
              ]
            },
          ],
          instagramLabel: "Bilder av våra produkter i <a href='https://www.instagram.com/min.kaka/'>Instagram</a>"
        }
      },
      {
        id: 2, title: "Kakor", type: "products",
        // data: {
        //   body: [
        //     "Vårt sortiment är mindre under sommarsäsomgen, 3.5. - 29.8.2021. På hösten erbjuder vi igen alla våra kakor"
        //   ]
        // },
        products: [
          // {
          //   id: 19, name: "Vessapapers&shy;rulle",
          //   image: 35,
          //   allergens: "Glutenfri, vegansk, äggfri, mjölkfri, nötfri",
          //   description: "Vessapapersrulle med innehåll av chokladmousse och hallon, som bakelse eller kaka.",
          //   ingredients: [29, 43, 39, 22, 0, 5, 27, 79, 60, 25, 49, 6],
          //   prices: 0
          // },
          {
            id: 1, name: "Jordgubbs&shy;moln med vanilj",
            image: 0,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Sockerkaksbotten med jordgubbssylt, vaniljkräm och grädde mellan bottnen. Ovanpå frusna inhemska jordgubbar och vackert vit gräddkant.",
            ingredients: [1, 5, 10, 22, 28, 46, 35, 78, 9, 0],
            prices: 0
          },
          {
            id: 2, name: "Hallon&shy;trädgården",
            image: 1,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Sockerkaksbotten med hallonmos, hallonsylt och hallonkvarg-grädde mellan bottnen. Ovanpå frusna inhemska hallon och vackert vit gräddkant.",
            ingredients: [1, 5, 10, 22, 28, 31, 79, 45, 9, 0],
            prices: 0
          },
          // {
          //   id: 11, name: "Kinuski-lingon&shy;kaka",
          //   image: 10,
          //   allergens: "Glutenfri, laktosfri, nötfri",
          //   description: "Sockerkaksbotten, med lingonsylt, kinuski och lingonkvarg-grädde mellan bottnen. Ovanpå mjuk kinuski och vackert vit gräddkant.",
          //   ingredients: [1, 5, 10, 22, 28, 31, 47, 36, 39, 41, 0],
          //   prices: 0
          // },
          {
            id: 7, name: "Prinsess&shy;kaka",
            image: 6,
            allergens: "Glutenfri, laktosfri",
            description: "Sockerkaksbotten med hallonmos, hallonsylt, vaniljkräm och grädde mellan bottnen. Ovanpå en härligt god marsipan med ett tunt lager smörkräm under.",
            ingredients: [1, 5, 10, 22, 79, 45, 28, 35, 24, 42, 41, 0],
            prices: 0
          },
          {
            id: 6, name: "Honungs&shy;kaka",
            image: 5,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Fem lager av mjuk honungskaka, med smetanakräm emellan.  Härlig smakuplevelse!",
            ingredients: [30, 5, 1, 24, 4, 22, 39, 41],
            prices: 0
          },
          {
            id: 3, name: "Apelsin-choklad&shy;kaka",
            image: 2,
            allergens: "Glutenfri, nötfri",
            description: "Chokladbotten med appelsinmarmelad, mörk chokladmousse och vitchokladmousse emellan. Ovanpå rinnande choklad.",
            ingredients: [28, 43, 44, 106, 1, 5, 59, 24, 22, 27, 61, 40, 0],
            prices: 0
          },
          {
            id: 4, name: "Triplachoklad&shy;kaka",
            image: 3,
            allergens: "Glutenfri, nötfri",
            description: "Chokladbotten med lager av mörk- och vitchokladmousse. Ovanpå ett lager chokladtryffel.",
            ingredients: [28, 43, 44, 59, 1, 5, 22, 24, 27, 61, 100, 102, 101, 40, 0],
            prices: 0
          },
          {
            id: 5, name: "Allergikerns choklad&shy;kaka",
            image: 4,
            allergens: "Glutenfri, vegansk, äggfri, mjölkfri, nötfri",
            description: "Chokladbotten med ett lager av härlig chokladmousse och en chokladspegel ovanpå.",
            ingredients: [29, 43, 22, 0, 5, 27, 60, 49, 6, 61, 63, 128],
            prices: 2
          },
          {
            id: 14, name: "Cashew&shy;ugnkaka",
            image: 41,
            allergens: "Glutenfri, vegansk, äggfri, mjölkfri",
            description: "Fylligt och fräch, i ugnen bakad kaka med smak av citron och hallon.",
            ingredients: [22, 73, 125, 5, 25, 29, 85, 79, 128, 63],
            prices: 0
          },
          {
            id: 10, name: "Svarvin&shy;bärs-choklad&shy;kaka",
            image: 9,
            allergens: "Glutenfri, nötfri, mjölkfri",
            description: "Chokladbotten med lager av svartvinbärsmousse och chokladmousse. Ovanpå en svartvinbärs spegel",
            ingredients: [1, 29, 66, 43, 22, 27, 61, 0, 59, 100, 101],
            prices: 0
          },
          // {
          //   id: 15, name: "Caffe Latte",
          //   image: 13,
          //   allergens: "Glutenfri, nötfri",
          //   description: "Kexbotten med en härlig moccamousse ovanpå. Marmorerad vitchokladstopping.",
          //   ingredients: [32, 23, 28, 44, 22, 24, 5, 59, 49, 43],
          //   prices: 0
          // },
          {
            id: 13, name: "Basilika-vitchoklad&shy;kaka",
            image: 12,
            allergens: "Glutenfri, nötfri",
            description: "Sockerkaksbotten med jordgubbsmos och basilika-vitchokladmousse ovanpå. Kronan på verket är jordgubbsspegeln.",
            ingredients: [28, 31, 44, 78, 5, 1, 22, 59, 0, 92, 62],
            prices: 0
          },
          // {
          //   id: 12, name: "Glacerad lime&shy;mousse&shy;kaka",
          //   image: 11,
          //   allergens: "Glutenfri, nötfri",
          //   description: "Kexbotten med fräsch limemousse och jordgubbsränder. Kakan är överdragen med vitchokladsglacering som är marmorerad i grönt.",
          //   ingredients: [32, 28, 83, 5, 78, 22, 24, 44, 8, 59, 41],
          //   prices: 0
          // },
          {
            id: 9, name: "Mango-passion&shy;mousse&shy;kaka",
            image: 8,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Kexbotten med mangomousse och en härligt syrlig passionfrukts topping.",
            ingredients: [103, 28, 32, 86, 104, 5, 24, 59, 22],
            prices: 0
          },
          {
            id: 8, name: "Ugnsost&shy;kaka",
            image: 7,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Kexbotten med fyllig ostkräm som har en smak av lime. Ovanpå kakan mjuk maräng.",
            ingredients: [32, 30, 1, 5, 81, 22, 24, 84, 0],
            prices: 0
          },
        ],
      },
      {
        id: 3, title: "Bakelser och salta bakverk", type: "products",
        products: [
          {
            id: 110, name: "Lax&shy;smörgås&shy;kaka",
            image: 24,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Brödbotten med en härlig mousse av varmrökt lax. Som dekoration citron, dill och rödlök.",
            ingredients: [32, 28, 52, 94, 24, 59, 85, 91, 5, 131, 121],
            prices: 4
          },
          {
            id: 111, name: "Skink&shy;smörgås&shy;kaka",
            image: 25,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Brödbotten med skinkmousse och som dekoration färska grönsaker.",
            ingredients: [32, 28, 51, 93, 94, 95, 96, 24, 59, 97, 98, 53],
            prices: 0
          },
          {
            id: 113, name: "Skärgårds&shy;smörgås&shy;tårta",
            image: 37,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Mellan de söta skivorna av skärgårdslimpa finns en läcker fyllning av rökt tofu och på toppen mormors gurka och inlagd rödlök.",
            ingredients: [121, 125, 127, 94, 95, 126, 91, 130, 85, 0, 5, 131],
            prices: 4
          },
          {
            id: 109, name: "Medel&shy;havs&shy;paj för 4-5 pers eller liten för en",
            image: 23,
            allergens: "Glutenfri, nötfri",
            description: "I det ostiga skalet finns en gräddig fyllning med smak från medelhavet.",
            ingredients: [28, 33, 24, 31, 1, 34, 54, 97, 118, 22],
            prices: 11
          },
          {
            id: 120, name: "Tomat-tofu&shy;­paj (en pers)",
            image: 45,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "På ett smakrikt botten, tofufyllning som piggas upp med tomat och lök.",
            ingredients: [142, 127, 94, 96, 15, 25, 125, 136, 137, 20, 27, 58, 64, 98],
            prices: 11
          },
          {
            id: 117, name: "Skink-purjo­löks&shy;paj (en pers)",
            image: 42,
            allergens: "Glutenfri, nötfri",
            description: "En traditionell gräddig och ostig skink- och purjopaj.",
            ingredients: [28, 33, 24, 31, 1, 51, 140, 96, 22],
            prices: 11
          },
          {
            id: 118, name: "Ris&shy;piråg",
            image: 43,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Inuti det krispiga skalet finns en krämig risgrynsgröt gjord på havredryck.",
            ingredients: [117, 135, 15, 0, 27, 136, 137, 20, 38, 58, 129],
            prices: 3
          },
          {
            id: 119, name: "Kanel&shy;bulle",
            image: 44,
            allergens: "Glutenfri, laktosfri",
            description: "Den äkta bullens mjukhet och munkänsla.",
            ingredients: [120, 138, 1, 5, 24, 3, 67, 139],
            prices: 12
          },
          // {
          //   id: 101, name: "Morots&shy;bakelse",
          //   image: 15,
          //   allergens: "Glutenfri, laktosfri",
          //   description: "Ovanpå den saftiga morotskakn finns en syrlig kräm, med smak av citrus.",
          //   ingredients: [99, 1, 5, 27, 12, 75, 21, 28, 82, 11, 10, 65, 41, 76],
          //   prices: 14
          // },
          {
            id: 102, name: "Passion&shy;bakelse",
            image: 16,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Kexbottnet har en fyllning av härlig passionfrukts kräm. På toppen ett lager av mjuk maräng.",
            ingredients: [1, 24, 5, 86, 22, 0, 63],
            prices: 12
          },
          {
            id: 104, name: "Kinuski-nöt&shy;bakelse",
            image: 18,
            allergens: "Glutenfri, laktosfri",
            description: "Kexbottnet har en fyllning av mjuk kinuski och rostade nötter.",
            ingredients: [36, 73, 74, 24, 1, 5, 22],
            prices: 12
          },
          {
            id: 107, name: "Banan cupcake",
            image: 21,
            allergens: "Glutenfri, vegansk, äggfri, mjölkfri",
            description: "Härlig banan cupcake med jordnöts topping.",
            ingredients: [5, 88, 25, 22, 114, 115, 116, 117, 60, 11, 74],
            prices: 12
          },
          {
            id: 112, name: "Morots&shy;cupcake",
            image: 36,
            allergens: "Glutenfri, vegansk, äggfri, mjölkfri",
            description: "Ovanpå den saftiga morots cupcake finns en vanilj kräm.",
            ingredients: [25, 5, 22, 99, 123, 29, 60, 65, 11, 62],
            prices: 12
          },
          {
            id: 114, name: "Banan&shy;bröd (450g)",
            image: 40,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Smakfullt, saftigt och sött bananbröd med solrosfrön.",
            ingredients: [88, 117, 5, 26, 17, 16, 132, 60, 65, 11],
            prices: 13
          },
          {
            id: 108, name: "Skärgårds&shy;limppa (500g)",
            image: 22,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Sött och gott",
            ingredients: [77, 7, 13, 14, 15, 16, 17, 18, 0, 3, 38, 19, 58, 20],
            prices: 13
          },
          {
            id: 115, name: "Ljust formbröd (1000g)",
            image: 39,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Lätt att skära i skivor och passar bra som smörgåsbröd eller matbröd.",
            ingredients: [0, 22, 15, 132, 27, 99, 26, 3, 20, 58],
            prices: 6
          },
          {
            id: 116, name: "Frösemla (100g)",
            image: 38,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Mjuk inuti och knaprig på ytan.",
            ingredients: [0, 22, 15, 132, 27, 133, 26, 3, 20, 58],
            prices: 5
          },
        ],

      },
      {
        id: 4, title: "Säsong", type: "products",
        products: [

          {
            id: 203, name: "Pepparkaks låda (140&nbsp;g)",
            image: 27,
            allergens: "Glutenfri, nötfri",
            description: "En låda innehåller 12-14 pepparkakor. Säljs vk 48 - 51.",
            ingredients: [22, 5, 24, 7, 1, 65, 67, 11, 68, 69],
            prices: 25
          },
          {
            id: 205, name: "Jultårtor",
            image: 29,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Säljs vk 48 - 51. Halvmånar med rejäl fyllning av hemlagad plommonsylt.",
            ingredients: [22, 31, 24, 90, 5, 0, 10, 27],
            prices: 26
          },
          {
            id: 204, name: "Dadelkaka à 500g",
            image: 28,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Säljs vk 48 - 51. Saftig traditionell dadelkaka!",
            ingredients: [5, 22, 89, 25, 0, 1, 28, 6, 11, 10],
            prices: 27
          },
          {
            id: 202, name: "Glöggkaka",
            image: 26,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Säljs vk 48 - 51. Pepparkaksbotten med en fyllning av kanel- och äppelsmaknde mousse. Glöggspegel.",
            ingredients: [32, 28, 56, 87, 5, 24, 57, 59, 65, 70],
            prices: 0
          },
          // {
          //   id: 103, name: "Lingon-vanilj&shy;bakelse",
          //   image: 17,
          //   allergens: "Glutenfri, laktosfri, nötfri",
          //   description: "Säljs vk 48 - 51. Kexbottnet har en fyllning av lingonsylt och vaniljkräm. På toppen ett lager av mjuk maräng.",
          //   ingredients: [35, 47, 1, 5, 22, 80, 0],
          //   prices: 12
          // },

          {
            id: 213, name: "Munkar (60g)",
            image: 34,
            allergens: "Glutenfri, vegansk, äggfri, mjölkfri",
            description: "För tillfället till hands ändast under Valborg, till salu tisdag 30.4..",
            ingredients: [117, 5, 16, 112, 25, 19, 20, 122, 11, 67, 134, 6],
            prices: 24
          },

          {
            id: 210, name: "Memma à 450g",
            image: 31,
            allergens: "Glutenfri, vegansk, äggfri, mjölkfri, nötfri",
            description: "Säljs vk 11 - 14.",
            ingredients: [0, 7, 107, 90, 13, 38, 5, 108, 58],
            prices: 22
          },
          {
            id: 211, name: "Pasha à 200g",
            image: 32,
            allergens: "Glutenfri, laktosfri",
            description: "Säljs vk 11 - 14.",
            ingredients: [31, 28, 5, 109, 24, 106, 76, 110],
            prices: 23
          },
          {
            id: 212, name: "Påskkaka",
            image: 33,
            allergens: "Glutenfri, laktosfri",
            description: "Säljs vk 11 - 14. Kexbotten med pasha och en guldgul mangospegel.",
            ingredients: [22, 24, 113, 103, 59, 43, 39],
            prices: 0
          },

          {
            id: 201, name: "Runebergs&shy;tårtor",
            image: 30,
            allergens: "Glutenfri, laktosfri",
            description: "Säljs vk 2 - 5. Runebergstårtor med smak av bittermandel, fuktade med romarom.",
            ingredients: [22, 105, 5, 76, 1, 0, 72, 48, 37, 124, 82],
            prices: 21
          },
        ],
      },
      {
        id: 7, title: "Veganska", type: "products",
        products: [
          {
            id: 5, name: "Allergikerns choklad&shy;kaka",
            image: 4,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Chokladbotten med ett lager av härlig chokladmousse och en chokladspegel ovanpå.",
            ingredients: [29, 43, 22, 0, 5, 27, 60, 49, 61, 6, 63, 128],
            prices: 2
          },
          {
            id: 14, name: "Cashew&shy;ugnkaka",
            image: 41,
            allergens: "Glutenfri, äggfri, mjölkfri",
            description: "Fylligt och fräch, i ugnen bakad kaka med smak av citron och hallon.",
            ingredients: [22, 73, 125, 5, 25, 29, 85, 79, 128, 63],
            prices: 0
          },
          {
            id: 113, name: "Skärgårds&shy;smörgås&shy;tårta",
            image: 37,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Mellan de söta skivorna av skärgårdslimpa finns en läcker fyllning av rökt tofu och på toppen mormors gurka och inlagd rödlök.",
            ingredients: [121, 125, 127, 94, 95, 126, 91, 130, 85, 0, 5, 131],
            prices: 4
          },
          {
            id: 107, name: "Banan cupcake",
            image: 21,
            allergens: "Glutenfri, äggfri, mjölkfri",
            description: "Härlig banan cupcake med jordnöts topping.",
            ingredients: [5, 88, 25, 22, 114, 115, 116, 117, 60, 11, 74],
            prices: 12
          },
          {
            id: 112, name: "Morots&shy;cupcake",
            image: 36,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Ovanpå den saftiga morots cupcake finns en vanilj kräm.",
            ingredients: [25, 5, 22, 99, 123, 29, 60, 65, 11, 62],
            prices: 12
          },
          {
            id: 114, name: "Banan&shy;bröd (450g)",
            image: 40,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Smakfullt, saftigt och sött bananbröd med solrosfrön.",
            ingredients: [88, 117, 5, 26, 17, 16, 132, 60, 65, 11],
            prices: 13
          },
          {
            id: 213, name: "Munkar (60g)",
            image: 34,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "För tillfället till hands ändast under Valborg, till salu tisdag 30.4..",
            ingredients: [117, 5, 16, 112, 25, 19, 20, 122, 11, 67, 134, 6],
            prices: 24
          },
          {
            id: 120, name: "Tomat-tofu&shy;­paj (en pers)",
            image: 45,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "På ett smakrikt botten, tofufyllning som piggas upp med tomat och lök.",
            ingredients: [142, 127, 94, 96, 15, 25, 125, 136, 137, 20, 27, 58, 64, 98],
            prices: 11
          },
          {
            id: 118, name: "Ris&shy;piråg",
            image: 43,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Inuti det krispiga skalet finns en krämig risgrynsgröt gjord på havredryck.",
            ingredients: [117, 135, 15, 0, 27, 136, 137, 20, 38, 58, 129],
            prices: 3
          },
          {
            id: 108, name: "Skärgårds&shy;limppa (500g)",
            image: 22,
            allergens: "Glutenfri, laktosfri, nötfri",
            description: "Sött och gott",
            ingredients: [77, 7, 13, 14, 15, 16, 17, 18, 0, 3, 38, 19, 58, 20],
            prices: 13
          },
          {
            id: 115, name: "Ljust formbröd (1000g)",
            image: 39,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Lätt att skära i skivor och passar bra som smörgåsbröd eller matbröd.",
            ingredients: [0, 22, 15, 132, 27, 99, 26, 3, 20, 58],
            prices: 6
          },
          {
            id: 116, name: "Frösemla (100g)",
            image: 38,
            allergens: "Glutenfri, äggfri, mjölkfri, nötfri",
            description: "Mjuk inuti och knaprig på ytan.",
            ingredients: [0, 22, 15, 132, 27, 133, 26, 3, 20, 58],
            prices: 5
          },
        ],
      },
      {
        id: 10, title: "Bakgrund", type: "background",
        data: {
          title: "Bakgrund",
          imageSet: 1,
          body: [
            "Jag är en konditor som önskar att samma kaka skulle kunna ätas av alla på festen, utan att man måsta pruta på smaken. Därför vill jag göra glutenfria produkter som smakar och är minst lika vackra som ”normala”.",
            "Min dröm var länge att ha ett glutenfritt bageri. Nu har jag ett i Hermanstad, i Helsingfors. Där gör vi salta och söta bakelsen som smakar och passar alla.",
            "Samma kaka för alla!"
          ]
        }
      },
      {
        id: 20, title: "Beställ", type: "order",
        data: {
          emailTemplate: "sv",
          form: {
            title: "Beställning",
            toggleAddProductFormLabel: "Tillsätt produkt till",
            productLabel: "Tillsätt produkt",
            sizeLabel: "Storlek",
            addProductLabel: "Tillsätt",
            totalPriceLabel: "Summa",
            pickupSectionLabel: "Avhämtnings tid",
            pickupLocationLabel: "Avhämtnings plats",
            pickupTimeLabel: "Upphämtningstid, tex. 2.1.2021 kl 14.00",
            notesLabel: "Andra önskemål",
            contactSectionLabel: "Kontakt information",
            nameLabel: "Namn",
            emailLabel: "E-mejl",
            phoneLabel: "Telefonnummer tex. 050 123456789",
            pickupFeeLabel: "Leveransavgift",
            pickupLocations: [
              {
                id: 1,
                name: "Hermanstad, Min Kaka",
                pickupTimes: "Ni kan avhämta er beställning vardagar 10-15, enligt överenskommelse. Betalningssätt bankkort eller kontant.",
                mapLink: 0,
                cost: 0,
              },
              {
                id: 5,
                name: "Sörnäs, Eromanga (lö. 9-14)",
                pickupTimes: "Ni kan avhämta er beställning lördag 9-14. Betalning enbart med räkning.",
                mapLink: 7,
                cost: 5,
              },
            ],
            orderLabel: "BESTÄLL",
            successDialog: {
              title: "TACK!",
              body: [
                "Den preliminära beställningen har skickats till oss – och en kopia av den till er, till given e-mejl adress.",
                "Vi bekräftar beställningen snarast, oftast följande vardag.",
              ],
            },
            failedDialog: {
              title: "SÄNDNING AV BESTÄLLNINGEN MISSLYCKADES",
              body: [
                ":("
              ],
              noNetwork: "Er nätförbindelse fungerar inte för tillfället.",
              serverError: "Vi har störningar i vår sevice, som vi försöker åtgärda snabbast möjligast – Under tiden kan ni kopiera er beställning och skicka oss den per mejl. Vi ber om ursäkt!"
            }
          },
          content: [
            {
              title: "Min Kaka har avslutat sin verksamhet 31.12.2024",
              body: [
                "Vi tackar våra kunder och samarbetspartners för de gemensamma åren."
              ]
            }
          ]
        }
      },
    ],

    ingredients: [
/* 0 */ "vatten",
/* 1 */ "ägg",
/* 2 */ "äggvita",
/* 3 */ "jäst",

/* 4 */ "honung",
/* 5 */ "socker",
/* 6 */ "vanilliinsocker",
/* 7 */ "sirap",
/* 8 */ "glukossirap",
/* 9 */ "gelesocker (socker, karragen, fruktkärnmjöl)",

/* 10 */"bakpulver",
/* 11 */"soda",
/* 12 */"mandelmjöl",
/* 13 */"tattarmjöl",
/* 14 */"linfrö",
/* 15 */"mörkt rismjöl",
/* 16 */"rismjöl",
/* 17 */"havreflingor",
/* 18 */"tattarkorn",
/* 19 */"potatisfiber",
/* 20 */"psyllium",
/* 21 */"majsstärkelse",
/* 22 */"Sempermix Fin mjölblandning (rismjöl, majsstärkelse, potatismjöl, modifierad poatisstärkelse, socker, stabiliseringsmedel (E412, E464, E401, E440), salt, jäsmedel (E575, E500))",

/* 23 */"mjölk",
/* 24 */"laktosfritt smör",
/* 25 */"margarin",
/* 26 */"olivolja",
/* 27 */"ryps- eller rapsolja",
/* 28 */"laktosfritt grädde",
/* 29 */"SOJAprodukt (sojabas (vatten, skalade SOJABÖNOR (2,7%)), vegetabiliska oljor (helt härdad kokosolja, helt härdad rapsolja), maltodextrin, solrosolja, socker, emulgeringsmedel (sackarosestrar av fettsyror, mjölksyraestrar av mono- och diglycerider av fettsyror), citrusfiber, havssalt, arom)",
/* 30 */"smetana",
/* 31 */"kvarg",

/* 32 */"laktosfritt färskost",
/* 33 */"emmentalost",
/* 34 */"salladsost ",

/* 35 */"vaniljkräm (mjölk, ägg, socker, smör, majsstärkelse)",
/* 36 */"kinuski (mjölk, socker, laktosfritt grädde)",
/* 37 */"pomada",
/* 38 */"sockerkulör",
/* 39 */"sockermassa",
/* 40 */"sokckerpärlor",
/* 41 */"livsmedelsfärg",
/* 42 */"marsipan (socker, mandel, glukossirap, invertssirap, vatten, konserveringsmedel(E211, E1103))",
/* 43 */"mörk choklad (kakaomassa, socker, kakaosmör, SOJAlecitin, naturlig vanilj-arom)",
/* 44 */"vitchoklad (socker, kakaosmör, helmjölkspulver, SOJAmjölkslecitin, naturlig vanilj-arom)",
/* 45 */"hallonsylt",
/* 46 */"jordgubbssylt",
/* 47 */"lingonsylt",
/* 48 */"hallonmarmelad",
/* 49 */"Nescafe-snabbckaffepulver",
/* 50 */"salmiakextrakt",
/* 51 */"skinka",
/* 52 */"varmrökt lax",
/* 53 */"glutenfritt bröd (majsstärkelse, vatten, surdeg 12% (rismjöl, vatten), risstärkelse, säd 4,3% (hirsmjöl 2,6%, quinoamjöl 1,7%) växtfiber (psyllium), sockerbetssirap, rissirap, solrosolja, SOJAgryn 2,1%, solrosfrön 2,1%, SOJAfiber 1,9%, linfrö 1,9%, förtjockningsmedel: hydroksipropylmetylcellulosa; hirsgryn 1,4%, SOJAprotein, jäst, havssalt, honung. Kan innehålla små mängder lupin.)",
/* 54 */"soltorkad tomat",
/* 55 */"torkad banan",
/* 56 */"Min Kaka -pepparkaka",
/* 57 */"glögg",

/* 58 */"salt",
/* 59 */"gelatin",
/* 60 */"vittvinsvinäger",
/* 61 */"kakao",
/* 62 */"vanilj",
/* 63 */"ätbara blommor",
/* 64 */"svartpeppar",
/* 65 */"kanel",
/* 66 */"svartvinbärspure",
/* 67 */"kardemumma",
/* 68 */"nejlika",
/* 69 */"ingefära",
/* 70 */"stjärnanis",
/* 71 */"bittermandelolja",
/* 72 */"romarom",

/* 73 */"CASHEWNÖT",
/* 74 */"HASSELNÖT",
/* 75 */"HACKAD HASSELNÖT",
/* 76 */"HACKAD MANDEL",

/* 77 */"apelsinsaft",
/* 78 */"jordgubb",
/* 79 */"hallon",
/* 80 */"lingon",
/* 81 */"lime",
/* 82 */"limesaft",
/* 83 */"limepure",
/* 84 */"vinbär",
/* 85 */"citron",
/* 86 */"passionfruktspure",
/* 87 */"äppelmos",
/* 88 */"banan",
/* 89 */"dadel",
/* 90 */"plommon",

/* 91 */"dill",
/* 92 */"basilika",
/* 93 */"paprika",
/* 94 */"rödlök",
/* 95 */"gurka",
/* 96 */"tomat",
/* 97 */"rucola",
/* 98 */"vitlök",
/* 99 */"morot",

/* 100 */"maräng",
/* 101 */"mörk blockchoklad",
/* 102 */"vit blockchoklad",
/* 103 */"mangopure",
/* 104 */"passionfrukt",
/* 105 */"smör",
/* 106 */"apelsinmarmelad (apelsin, citron, socker, vatten)",
/* 107 */"potatis",
/* 108 */"pomerans",
/* 109 */"ägggula",

/* 110 */"russin",
/* 111 */"joghurt",
/* 112 */"potatismjöl",
/* 113 */"Min Kaka -pasha",
/* 114 */"JORDNÖT",
/* 115 */"solrosolja",
/* 116 */"härdat palmfett",
/* 117 */"havredryck (havrebas (vatten, glutenfritt HAVREMJÖL, ryps- och rapsolja, kalcium, salt), vatten, surhetsreglerande medel (kaliumfosfater), jod, vitaminer (riboflavin (B2), B12 och D2))",
/* 118 */"oliv",
/* 119 */"agavesirap",

/* 120 */"laktosfritt mjölk",
/* 121 */"Min Kaka skärgårdslimppa",
/* 122 */"citronpure",
/* 123 */"ananas",
/* 124 */"bittermandelarom",
/* 125 */"Sheese (vatten, kokosolja, SOJABÖNSkoncentrat, salt, mjölksyra (mjölkfri), socker, förtjockningsmedel (karragenan), naturliga smakämnen)",
/* 126 */"vegansk majoneesi (rapsolja, vatten, ättika, socker, salt, modifierad majsstärkelse, naturlig arom (innehåller SENAP), citronsaftconcentrat, surhetsreglerandemedel (E385), paprikaextrakt)",
/* 127 */"rökt tofu (SOJABÖNÖR, vatten, havssalt, stabileringsmedel (kalsiumsulfat), rökarom)",
/* 128 */"agaragar",
/* 129 */"xantangummi",

/* 130 */"kapris",
/* 131 */"ättiksprit",
/* 132 */"solrosfrö",
/* 133 */"pumpafrö",
/* 134 */"rapsolja",
/* 135 */"ris",
/* 136 */"modifierad majsstärkelse",
/* 137 */"potatisflingor (potatis, emulgeringsmedel (E471), stabiliseringsmedel (E450), antioxidationsmedel (E300), surhetsreglerande medel (E330, E331))",
/* 138 */"mjölblandning (stärkelse (majs, potatis, tapioka), psyllium, inulin, fruktos, förtjockningsmedel (E412, E464), emulgeringsmedel (E401), vegetabiliskt protein (ärt), rostad majsmjöl, salt, konserveringsmedel (E200), enzymer)",
/* 139 */"kanelinnehåll (socker, vatten, aprikoskärnor, glukossirap, modifierad potatisstärkelse, margarin (palmolja, sheaolja, rapsolja), salt, emulgeringsmedel (E471, E475), arom, antioxidationsmedel (E350), färgämne (E160a), MANDEL, kanel, alkohol, invertsirap, konserveringsmedel (E202), lönnsirap)",

/* 140 */"purjolök",
/* 141 */"mjölblandning (venusris, durra, rött teff, bovete, frömjölblandning (linfrö, chia), vegetabiliska fibrer (psyllium, inulin), fruktos, äppleextrakt, förtjockningsmedel (E412, E464), rostat majsmjöl, enzymer)",
/* 142 */"vegetabilisk olja-fettblandning (linsproteinpreparat (vatten, 1,1 % linsprotein), kokosfett, rapsolja, socker, modifierad majsstärkelse, emulgeringsmedel (solroslecitin, fettsyrester av sackaros, mono- och diglycerider av fettsyror), stabiliseringsmedel (guarkärnmjöl, johannesbrödkärnmjöl), naturliga aromer, salt, färgämne (betakaroten))",

    ],
    sizes: {
      "X": { single: "Ca. 4 pers" },
      "K": { single: "Ca. 8 pers" },
      "S": { single: "Ca. 10 pers" },
      "M": { single: "Ca. 15 pers" },
      "L": { single: "Ca. 20 pers" },
      "R": { single: "Från 5€/pers – slutgiltig summa meddelas senare" },
      "P": { single: "1 bakelse", multiple: "%%COUNT%% stycken" },
      "p": { single: "1 liten pirog", multiple: "%%COUNT%% små piroger" },
      "B": { single: "1 st.", multiple: "%%COUNT%% stycken" },
      "A": { single: "1 ask, en ask innehåller 12 pepparkakor", multiple: "%%COUNT%% askar, en ask innehåller 12 pepparkakor" },
      "C": { single: "1 st.", multiple: "%%COUNT%% stycken" },
    },
    texts: {
      ingredientLabel: "Innehållsförtecknig",
      addProductLabel: "Tillsätt till beställningen",
      pricesLabel: "Välj storlek",
      closeLabel: "Ok",
    },
    contact: {
      name: "Bettina Winquist, Min Kaka",
      address: "Hermanstads strandväg 20, 00580 Helsingfors",
      phone: "(040) 562 4011",
      email: "bettina@minkaka.fi",
      oiva: {
        text: "Oiva-raporten",
        url: "https://www.oivahymy.fi/sv/sok-foretag/#text=min%20kaka%20oy&",
      },
    }

  },
  shared: {
    imageSets: [
      { // 0
        mainImage: "/images/joulupoyta.jpg",
        secondaryImage: [
          "/images/pipareita.jpg",
          "/images/leipa.jpg",
        ]
      },
      { // 1
        mainImage: "/images/bettina.jpg",
        secondaryImage: [
          "/images/kukkakakku2.jpg",
          "/images/karkkikakku.jpg",
        ]
      },
      { // 2
        mainImage: "/images/banaanimuffinitfiilis.jpg",
        secondaryImage: [
          "/images/hunajakakkuzoom.jpg",
          "/images/leipa.jpg",
        ]
      },
      { // 3
        mainImage: "/images/paasiainen2.jpg",
        secondaryImage: [
          "/images/leipa.jpg",
          "/images/pullapelti.jpg",
        ]
      },
      { // 4
        mainImage: "/images/kerroskakku4.jpg",
        secondaryImage: [
          "/images/leipa.jpg",
          "/images/maapahkinamuffini2.jpg",
        ]
      },
      { // 5
        mainImage: "/images/runeberg.jpg",
        secondaryImage: [
          "/images/allergikonzoom.jpg",
          "/images/maapahkinamuffini2.jpg",
        ]
      },
      { // 6
        mainImage: "/images/wcrulla3.jpg",
        secondaryImage: [
          "/images/paasiainen3.jpg",
          "/images/maapahkinamuffini2.jpg",
        ]
      },
      { // 7
        mainImage: "/images/munkkilaatikko2.jpg",
        secondaryImage: [
          "/images/wcrulla2.jpg",
          "/images/maapahkinamuffini2.jpg",
        ]
      },
      { // 8
        mainImage: "/images/basilikazoom.jpg",
        secondaryImage: [
          "/images/allergikonzoom.jpg",
          "/images/hunajakakkuzoom.jpg",
        ]
      },
      { // 9
        mainImage: "/images/munkkilaatikko2.jpg",
        secondaryImage: [
          "/images/allergikonzoom.jpg",
          "/images/maapahkinamuffini2.jpg",
        ]
      },
      { // 10
        mainImage: "/images/saaristolaisvoileipakakkufiilis.jpg",
        secondaryImage: [
          "/images/allergikonzoom.jpg",
          "/images/maapahkinamuffini2.jpg",
        ]
      },

    ],
    mapLinks: [
      "https://goo.gl/maps/ZD1dxwaZ8b42",
      "https://goo.gl/maps/vEut25WE4Wr",
      "https://goo.gl/maps/pFsE63PdgS62",
      "https://goo.gl/maps/uAXK92KbJNy",
      "https://goo.gl/maps/V74G64wUxtJGfT2X7",
      "https://goo.gl/maps/74K9aKvm3xZsNw6v6",
      "https://g.page/Kotitila?share",
      "https://maps.app.goo.gl/LsPi89Sk6CQNKGYb9"
    ],
    priceSets: [
      // 0 - 10
      [
        { size: "S", price: 50 },
        { size: "M", price: 65 },
        { size: "L", price: 80 }
      ],
      [
        { size: "p10", price: 45 },
      ],
      [
        { size: "X", price: 15 },
        { size: "S", price: 50 }
      ],
      [
        { size: "P10", price: 20 },
      ],
      [
        { size: "S", price: 60 },
        { size: "M", price: 75 },
        { size: "L", price: 90 }
      ],
      [
        { size: "P20", price: 40 },
      ],
      [
        { size: "B2", price: 24 },
        { size: "B5", price: 60 },
        { size: "B10", price: 120 }
      ],
      [
        { size: "E", price: 0 }
      ],
      [
        { size: "E", price: 0 }
      ],
      [
        { size: "E", price: 0 }
      ],
      [
        { size: "E", price: 0 }
      ],

      // 11 - 20
      [
        { size: "X", price: 16 },
        { size: "p10", price: 45 },
      ],
      [
        { size: "P10", price: 35 },
      ],
      [
        { size: "B1", price: 9 },
        { size: "B5", price: 45 },
        { size: "B10", price: 90 },
      ],
      [
        { size: "E", price: 0 }
      ],
      [
        { size: "E", price: 0 }
      ],
      [
        { size: "E", price: 0 }
      ],
      [
        { size: "E", price: 0 }
      ],
      [
        { size: "E", price: 0 }
      ],
      [
        { size: "E", price: 0 }
      ],
      [
        { size: "E", price: 0 }
      ],

      // 21
      [
        { size: "P2", price: 8 },
        { size: "P10", price: 40 },
      ],
      [
        { size: "B1", price: 7 },
        { size: "B4", price: 28 },
        { size: "B10", price: 70 },
      ],
      [
        { size: "B1", price: 6 },
        { size: "B4", price: 24 },
        { size: "B10", price: 60 },
      ],
      [
        { size: "P4", price: 10 },
        { size: "P10", price: 25 },
        { size: "P20", price: 50 },
      ],
      [
        { size: "B1", price: 5.5 },
        { size: "B4", price: 22 },
        { size: "B10", price: 55 },
      ],
      [
        { size: "P5", price: 15 },
        { size: "P10", price: 30 },
      ],
      [
        { size: "C1", price: 12 },
        { size: "C5", price: 60 },
        { size: "C10", price: 120 },
      ],

    ],
    productImages: [
      "/images/mansikkakakku.jpg",
      "/images/vadelmakakku.jpg",
      "/images/suklaaunelmakakku.jpg",
      "/images/kolmensuklaankakku.jpg",
      "/images/suklaamoussekakku.jpg",

      "/images/hunajakakku.jpg",
      "/images/prinsessakakku.jpg",
      "/images/juustokakku.jpg",
      "/images/passionmangokakku.jpg",
      "/images/marjasuklaakakku.jpg",

      "/images/kinuskikakku.jpg", //10
      "/images/limekakku3.jpg",
      "/images/kiillekakku.jpg",
      "/images/mokkakakku.jpg",
      "/images/karkkikakku.jpg",

      "/images/porkkanaleivokset.jpg",
      "/images/marenkileivokset.jpg",
      "/images/puolukkaleivokset.jpg",
      "/images/kinuskileivokset.jpg",
      "/images/salmiakkimarenki.jpg",

      "/images/kaakaomarenki.jpg", //20
      "/images/banaanicupcake.jpg",
      "/images/saaristolaisleipa.jpg",
      "/images/valimerellinenpiirakka.jpg",
      "/images/lohivoileipakakku.jpg",

      "/images/kinkkuvoileipakakku.jpg",
      "/images/glogikakku.jpg",
      "/images/piparkakkuja.jpg",
      "/images/taatelikakku.jpg",
      "/images/joulutortut.jpg",

      "/images/runebergintortut.jpg", //30
      "/images/mammi3.jpg",
      "/images/pasha.jpg",
      "/images/paasiaiskakku.jpg",
      "/images/munkit.jpg",

      "/images/wcrulla.jpg",
      "/images/porkkanacupcake.jpg",
      "/images/saaristolaisvoileipakakku.jpg",
      "/images/siemensampyla.jpg",
      "/images/porkkanaleipa.jpg",

      "/images/banaanileipa.jpg", //40
      "/images/cashewuunikakku.jpg",
      "/images/kinkkupiirakka.jpg",
      "/images/karjalanpiirakat.jpg",
      "/images/pulla.jpg",

      "/images/vegaaninenpiirakka.jpg",
      "/images/bagel.jpg",
    ]
  },
}